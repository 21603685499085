import { Product } from '@framework/model-types/Product'
import { toast } from 'react-toastify'
export interface Item {
  id: string | number
  productId: string
  price: number
  name: string
  type: string
  slug: string
  sku: string
  stock: number
  maxOrderLimit: number
  minOrderLimit: number
  cartIncrementStep: number
  fullPrice: number
  quantity?: number
  itemTotal: number
  image: {
    uuid: string
    media_type: string
  }
  variant: Product['variants'][number] | null
}

export interface UpdateItemInput extends Partial<Omit<Item, 'id'>> {}

export function addItemWithQuantity(
  items: Item[],
  item: Item,
  quantity: number,
) {
  if (quantity <= 0)
    throw new Error("Cart quantity can't be zero or less than zero")
  const existingItemIndex = items.findIndex(
    (existingItem) => existingItem.id === item.id,
  )
  const newItems = [...items]

  const existingItem = newItems[existingItemIndex]

  const itemToAdd = existingItem ?? item

  const { maxOrderLimit, quantity: itemQty = 0, stock, name } = itemToAdd

  const isBelowMax = !maxOrderLimit || itemQty + quantity <= maxOrderLimit

  const canAddItem =
    isBelowMax &&
    stock >= 1 &&
    stock >= (item?.minOrderLimit ?? 0) &&
    stock >= quantity + itemQty

  if (!isBelowMax) {
    toast.error(
      `You can only add ${maxOrderLimit} items of ${name} to your cart`,
    )
  }

  if (!canAddItem) {
    return newItems
  }
  if (existingItemIndex > -1) {
    newItems[existingItemIndex].quantity! += quantity
    return newItems
  }
  return [...items, { ...item, quantity }]
}

export function removeItemOrQuantity(
  items: Item[],
  id: Item['id'],
  quantity?: number,
) {
  return items.reduce((acc: Item[], item) => {
    if (item.id === id) {
      const { cartIncrementStep, minOrderLimit } = item
      const newQuantity =
        minOrderLimit && item.quantity! === minOrderLimit
          ? 0
          : item.quantity! - (quantity ?? cartIncrementStep)

      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc]
    }
    return [...acc, item]
  }, [])
}
// Simple CRUD for Item
export function addItem(items: Item[], item: Item) {
  return [...items, item]
}

export function getItem(items: Item[], id: Item['id']) {
  return items.find((item) => item.id === id)
}

export function updateItem(
  items: Item[],
  id: Item['id'],
  item: UpdateItemInput,
) {
  return items.map((existingItem) =>
    existingItem.id === id ? { ...existingItem, ...item } : existingItem,
  )
}

export function removeItem(items: Item[], id: Item['id']) {
  return items.filter((existingItem) => existingItem.id !== id)
}

export function inStock(items: Item[], id: Item['id']) {
  const item = getItem(items, id)
  if (item) return item['quantity']! < item['stock']!
  return false
}

export function remaininginStock(
  items: Item[],
  id: Item['id'],
  product?: { selectedQuantity: number; currentStock: number },
) {
  const item = getItem(items, id)
  if (item) {
    let remainder = item['stock']! - item['quantity']!
    return remainder > 0 ? remainder : 0
  } else if (product?.currentStock && product?.selectedQuantity !== undefined) {
    return product.currentStock - product.selectedQuantity > 0
      ? product.currentStock - product.selectedQuantity
      : 0
  }
  return 0
}

export const calculateItemTotals = (items: Item[]) =>
  items.map((item) => ({
    ...item,
    itemTotal: item.price * item.quantity!,
  }))

export const calculateTotal = (items: Item[]) =>
  items.reduce((total, item) => total + item.quantity! * item.price, 0)

export const calculateTotalItems = (items: Item[]) =>
  items.reduce((sum, item) => sum + item.quantity!, 0)

export const calculateUniqueItems = (items: Item[]) => items.length
